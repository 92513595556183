@charset "utf-8";
#wrap {width: 100%;height: 100%;position:relative;z-index:1;}
#mainWrap{position: absolute;top: 0;right: 0;left: 0;bottom: 0;width: 100%;height: 100%;overflow: hidden;}

#headerWrap{position: absolute;top: 0;left: 0;right: 0;height: 94px;margin: 0;box-sizing: border-box; z-index: 101;}
#headerWrap #headFnc{position: absolute;top: 0;left: 0;right: 0;width: 100%;height: 70px;background: #ffffff;}

#headerWrap #headFnc #main-ci{position: absolute;top: 0;left: 0;width: 200px;height: 70px;margin: 0;padding: 5px 0 5px 20px;background-color: #fff;transition: left .3s ease-in;transition-delay: .3s;box-sizing: border-box;}
#headerWrap #headFnc #main-ci #logo{overflow: hidden;position: absolute;top: 5px;left: 10px; margin: 0; font-size: 1px; height:40px; line-height:50px;}
#headerWrap #headFnc #main-ci #logo img{max-width: 180px;max-height: 40px;vertical-align: middle;cursor: pointer;}

#headerWrap #headFnc .headfnc{position: absolute;top: 0;right: 20px;height: 50px;}




/* 컨텐츠 탭 */
#headerWrap #headTabs{position: absolute;top: 0px;left: 0;right: 325px;height: 50px;overflow:hidden;}

#headerWrap #headTabs .tabs{position: absolute;top: 10px;left: 0px;right: 0px;bottom: 0px;font-size: 0;white-space: nowrap;}
#headerWrap #headTabs .tabs:after {content:"";display:block;clear:both;height:0;visibility:hidden;}
#headerWrap #headTabs .tabs .tab-item{position: relative;display: inline-block;width: 138px;height: 32px;padding: 0 23px 0 0px;border-style: solid;border-radius: 3px;border: solid 1px #e6e6e6;box-sizing: border-box;vertical-align: top;margin-right:2px;}
#headerWrap #headTabs .tabs .tab-item .react-contextmenu-wrapper {padding-left: 34px;}
#headerWrap #headTabs .tabs .tab-item:hover{  border: solid 1px #8c8c8c;transition:all 0.3s;}
#headerWrap #headTabs .tabs .tab-item.rightOn{  border: solid 1px #8c8c8c;transition:all 0.3s;}
#headerWrap #headTabs .tabs .tab-item .module-name{display: block;font-size: 12px;font-weight: 200;color: #8c8c8c;line-height: 2;letter-spacing: -0.5px;padding: 3px 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;}
#headerWrap #headTabs .tabs .tab-item .item-close{position: absolute;top: 7px;right: 6px;width: 16px;height: 16px;background: url('../imgs/ico/ic_close_01_s_normal.png') no-repeat center;cursor: pointer;}
#headerWrap #headTabs .tabs .tab-item.on .module-name {color:#0e7add;}
#headerWrap #headTabs .tabs .tab-item:active {background-color: #eff7ff;}
#headerWrap #headTabs .tabs .tab-item.on{ border: solid 1px #0e7add;box-sizing: border-box;}
#headerWrap #headTabs .tabs .tab-item.on .new-window{background: url('../imgs/ico/ic_write_newpage_m_over.png') no-repeat center;}
#headerWrap #headTabs .tabs .tab-item.on .item-close{background: url('../imgs/ico/ic_close_01_s_over.png') no-repeat center;}
#headerWrap #headTabs .tab-more-out {position: absolute;top: 10px;right: 0;bottom: 0;width: 26px;height: 32px;width: 26px;height: 32px;box-sizing: border-box;background:#fff}
#headerWrap #headTabs .tab-more{width: 26px;height: 32px;box-sizing: border-box;background:#fff url('../imgs/ico/ic_more_m_normal.png') no-repeat center;cursor: pointer;border: solid 1px #e6e6e6;  border-radius: 3px;}
#headerWrap #headTabs .tab-more:hover{border: solid 1px #8c8c8c;transition:all 0.3s;background:#fff url('../imgs/ico/ic_more_m_over.png') no-repeat center;}
#headerWrap #headTabs .tab-more:active {background-color:#eff7ff}

/*헤더 타이틀 영역*/
#headerWrap #headerTitle {height: 44px; width: 100%; background: #F9B002; position: absolute; top: 60px;overflow: hidden;}
#headerWrap #headerTitle .tit {font-size:18px;font-weight:bold; line-height: 48px;color:#fff;float: left;padding-left: 1px;}
#headerWrap #headerTitle .menuLnb {width:44px; height:44px;float: left;background: url('../imgs/ico/icon_menu.png') no-repeat center center;cursor:pointer;}
#headerWrap #headerTitle .menuLnb:hover {background:#0259ac url('../imgs/ico/icon_menu.png') no-repeat center center;}
#headerWrap #headerTitle .menuLnb.folding {background: url('../imgs/ico/icon_menu_open.png') no-repeat center center;}
#headerWrap #headerTitle .menuLnb.folding:hover {background:#0259ac url('../imgs/ico/icon_menu_open.png') no-repeat center center;}

#headerWrap #headTabs .tabs .tab-item .module-name.toolTipOn + .module-name-clone + .pubTooltip {visibility: visible;}
#headerWrap .tab-item .module-name-clone {position: fixed;left:-50000px; display: block; font-size: 12px; font-weight: 200; color: #8c8c8c; line-height: 2; letter-spacing: -0.5px; padding: 3px 0;}


/*메뉴더보기*/
.miscMore{display:none;position: absolute;z-index: 100;border: 1px solid #a6a6a6;}
.miscMore:before{content:'';position: absolute;top: -8px;left: 80%;margin-left:-9px;display: block;width: 18px;height: 9px;background: url('../imgs/bg/bg_popup_up_arrow.png') no-repeat center;}
.miscMore ul{width: 128px; box-sizing: border-box;box-shadow: 0 2px 6px rgba(0,0,0,0.12);max-height:271px;overflow-y:auto;}
.miscMore ul li{float:inherit !important;margin:0 !important; min-width:auto !important;min-height:auto !important;position: relative;display: block;box-sizing: border-box;width: 100%;height: 27px;padding: 0 20px 0 11px;background-color: #ffffff;cursor:pointer;}
.miscMore ul li:hover {color:#1c90fb;background: #eff7ff;}
.miscMore span.ico{width: 17px;height: 17px;float: left;margin-top: 5px;margin-right: 4px; }
.miscMore span.txt {line-height: 27px;float: left;}

.tab-more-popup{display:none;position: absolute;z-index: 100;border: 1px solid #a6a6a6;}
.tab-more-popup:before{content:'';position: absolute;top: -8px;left: 50%;margin-left:-9px;display: block;width: 18px;height: 9px;background: url('../imgs/bg/bg_popup_up_arrow.png') no-repeat center;}
.tab-more-popup ul{width: 228px; box-sizing: border-box;box-shadow: 0 2px 6px rgba(0,0,0,0.12);max-height:271px;overflow-y:auto;}
.tab-more-popup ul .tab-item{position: relative;display: block;box-sizing: border-box;width: 100%;height: 27px;padding: 0 20px 0 26px;background-color: #ffffff;}
.tab-more-popup ul .tab-item:first-child{border-width: 0px;}
.tab-more-popup ul .tab-item:hover{background-color: #e6f5ff;transition: all 0.3s;}
.tab-more-popup ul .tab-item.rightOn{background-color: #f5f5f5;transition: all 0.3s;}
.tab-more-popup ul .tab-item.on{background-color: #e6f5ff;}
.tab-more-popup ul .tab-item .new-window{position: absolute;top: 0px;left: 0px;width: 26px;height: 27px;background: url('../imgs/ico/ic_write_newpage_m_normal.png') no-repeat center;cursor: pointer;}
.tab-more-popup ul .tab-item .module-name{display: block;font-size: 12px;font-weight: 300;color: #000000;line-height: 27px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;}
.tab-more-popup ul .tab-item .item-close{position: absolute;top: 0px;right: 3px;width: 16px;height: 27px;background: url('../imgs/ico/ic_close_01_s_normal.png') no-repeat center;cursor: pointer;}
.tmp-foot {height:40px; background:#f7f7f7;text-align: center;box-sizing: border-box; padding-top:8px;}

#containerWrap{position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: 0;/*min-width: 1232px;min-height: 840px;*/overflow: hidden;z-index: 1;overflow-x:auto;}
#headerWrap #headFnc .headfnc .head-misc{float: left;height: 50px;}

#headerWrap #headFnc .headfnc:after {content:"";display:block;clear:both;height:0;visibility:hidden;}

//#headerWrap #headFnc .headfnc .head-misc{float: left;height: 50px;}
//#headerWrap #headFnc .headfnc .head-misc ul li{float: left;position: relative;margin: 10px 0 0 6px;min-width:30px;min-height:30px;}
//#headerWrap #headFnc .headfnc .head-misc ul li span.btn{display: block;width: 30px;height: 30px;background: transparent no-repeat center;cursor: pointer;}
//#headerWrap #headFnc .headfnc .head

/* lnb */
#containerWrap #sideLnb{position: absolute;top: 104px;left: 0;bottom: 0;min-width: 194px;border: solid #e5e5e5;border-width: 0 1px 0 0;box-sizing: border-box;background: #f6f8fa;transform: translateX(0%);transition: transform 0.5s;/* z-index: 3; */ z-index:11;}

#containerWrap #sideLnb .lnbTab{width:100%;height:40px;padding:0 15px;box-sizing: border-box;border-top:1px solid #dedede;border-bottom:1px solid #dedede;}
#containerWrap #sideLnb .lnbTab .menu{cursor: pointer;background: url('../imgs/ico/ico_lnbTab_menu.png') no-repeat center;}
#containerWrap #sideLnb .lnbTab .menu:hover{position: relative;background: url('../imgs/ico/ico_lnbTab_menu_on.png') no-repeat center;}
#containerWrap #sideLnb .lnbTab .menu.on{position: relative;background: url('../imgs/ico/ico_lnbTab_menu_on.png') no-repeat center;}
#containerWrap #sideLnb .lnbTab .menu.on::after{content:'';position: absolute;bottom: -1px;width:100%;height:3px;background: #0c89fb;}

#containerWrap #sideLnb .lnb-container{position: absolute;top: 0;left: 0;right: 0;bottom: 0;overflow: auto;}
#containerWrap #sideLnb .lnb-container .nav-item{box-sizing: border-box;cursor: pointer;}
#containerWrap #sideLnb .lnb-container .nav-item .extend{position: relative;display: flex;font-size: 0;padding:6px 0 5px 0;}

//#containerWrap #sideLnb .lnb-container .nav-item.step-3 {padding-left: 16px;background: url('../imgs/bg/bg_dat.png') repeat-y 15px 0px;}
#containerWrap #sideLnb .lnb-container .nav-item.nav-item-open > .extend .nav-icon {background:url('../imgs/ico/btn_tree_open.png') no-repeat 0px 10px,#fff url('../imgs/bg/bg_datL2.png') no-repeat 3px 0px; margin-top: -6px;height:20px;}
#containerWrap #sideLnb .lnb-container .nav-item.nav-item-close > .extend .nav-icon {background:url('../imgs/ico/btn_tree_close.png') no-repeat 0px 10px,#fff url('../imgs/bg/bg_datL2.png') no-repeat 3px 0px; margin-top: -6px;height:20px;}

#containerWrap #sideLnb .lnb-container .nav-item .extend .nav-icon{display: inline-block;width: 15px;height: 9px;background:#fff url('../imgs/bg/bg_datL.png') no-repeat 3px 0px;}
#containerWrap #sideLnb .lnb-container .nav-item .extend .nav-text{display: block;flex: 1;font-size: 13px;line-height: 17px;letter-spacing: -0.5px;padding: 0 0 0 3px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
#containerWrap #sideLnb .lnb-container .nav-item.selected .nav-text{font-weight:bold;}
#containerWrap #sideLnb .lnb-container .nav-item .extend .nav-text-cnt{display: block;height: 16px;font-size: 12px;line-height: 16px;text-align: right;}
#containerWrap #sideLnb .lnb-container .nav-item.step-1 > .extend .nav-text-cnt{margin-top:13px;}
#containerWrap #sideLnb .lnb-container .nav-item .extend + .sub-container{height: 0;transform: rotateX(-90deg);}
#containerWrap #sideLnb .lnb-container .nav-item.nav-item-open > .extend + .sub-container{height: auto;transform: rotateX(0deg);transition: 0.5s;}
#containerWrap #sideLnb .lnb-container .nav-item.item-folder > .extend .nav-icon{display: inline-block;width: 15px;height: 16px;background: url('../imgs/ico/ic_arrow_right_02_m_normal.png') no-repeat center;}
#containerWrap #sideLnb .lnb-container .nav-item.item-folder.nav-item-open > .extend .nav-icon{display: inline-block;width: 15px;height: 16px;background: url('../imgs/ico/ic_arrow_left_02_m_normal.png') no-repeat center;}
#containerWrap #sideLnb .lnb-container .nav-item > .extend .nav-text:hover {color: #1c90fb;}
#containerWrap #sideLnb .lnb-container .nav-item > .extend .nav-text:hover + .nav-text-cnt{color: #1c90fb;}
#containerWrap #sideLnb .lnb-container .nav-item.selected > .extend{color: #1c90fb;}

#containerWrap #sideLnb .lnb-container .nav-item {padding-left: 20px;}

#containerWrap #sideLnb .lnb-container .nav-item.step-1{padding:0px;border: solid #e1e6ec;border-width: 0 0 1px 0;}
#containerWrap #sideLnb .lnb-container .nav-item.step-1 > .extend{padding:0;padding: 0px 15px 0 20px;}
#containerWrap #sideLnb .lnb-container .nav-item.step-2 > .extend{padding-right: 15px;}
#containerWrap #sideLnb .lnb-container .nav-item.step-3 > .extend{padding-right: 15px;}
#containerWrap #sideLnb .lnb-container .nav-item.step-1 > .extend .nav-icon{display:none !important;}
#containerWrap #sideLnb .lnb-container .nav-item.step-1 > .extend .nav-text{display: inline-block;font-size: 16px;line-height: 2;letter-spacing: -0.5px;padding: 5px 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
#containerWrap #sideLnb .lnb-container .nav-item.step-1 > .extend .nav-text:hover {color: #1c90fb;}

#containerWrap #sideLnb .lnb-container .nav-item > .extend .item-close {width: 16px; height: 16px;vertical-align: top; background: url("../imgs/ico/ic_close_01_s_normal.png") no-repeat center; cursor: pointer; display: inline-block;margin-left:2px;}
#containerWrap #sideLnb .lnb-container .nav-item > .extend .item-close:hover {background: url("../imgs/ico/ic_close_01_s_over.png") no-repeat center;}
#containerWrap #sideLnb .lnb-container .nav-item.step-1 > .extend .item-close {margin-top: 7px;;}

.sideTab { height:100%;}

.sideRegi + .lnb-container{top: 61px !important;left: 0;right: 0;bottom: 0;overflow: auto;}

.sideLnbMenu {background:#f6f8fa;position:relative;border-top:1px solid #d9d9d9;
  .lnb-container {overflow:initial !important;}
}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1 {padding:0;border-bottom: 1px solid #d9d9d9;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1 > .extend {height:45px;border-bottom: 1px solid #d9d9d9;padding: 0 15px 0 20px;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1:last-child > .extend {border-bottom:none;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1.nav-item > .extend {border-bottom:none}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1.nav-item.nav-item-open > .extend {border-bottom:1px solid #d9d9d9;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1.nav-item.nav-item-open:last-child > .extend {border-bottom:1px solid #d9d9d9;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item .extend + .sub-container {padding: 4px 12px 4px 0px;background: #fff;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-2 .extend + .sub-container {padding: 0px;}
//#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1 > .extend .nav-text {font-weight:bold}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-2 > .extend .nav-text {font-size:13px;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1.nav-item-open > .extend{background: url('../imgs/ico/ic_arrow_down_01_m_normal.png') no-repeat right 8px center;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1.nav-item-close > .extend{background: url('../imgs/ico/ic_arrow_up_01_m_normal.png') no-repeat right 8px center;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1.nav-item-close .extend + .sub-container {padding:0;margin-top:-1px;}
#containerWrap #sideLnb .lnb-container .nav-item.step-3 .extend {}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1 > .extend .nav-text-cnt{margin-right: 15px;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1 > .extend .nav-setting{width:14px;height:14px;margin:13px 15px 0 5px;background: url('../imgs/ico/ic_settine_02_s_normal.png') no-repeat center;}
#containerWrap #sideLnb .lnb-container.snbCon .nav-item.step-1 > .extend .nav-setting:hover{background: url('../imgs/ico/ic_settine_02_s_over.png') no-repeat center;}

#containerWrap #sideLnb .sizeTool{position: absolute;top: 0;right: -7px;bottom: 0px;width: 6px;border: solid #dddddd;border-width: 1px 1px 0 0;background: #f4f4f4;box-sizing: border-box;cursor: ew-resize;}
#containerWrap #sideLnb .sizeTool .sizeToolBtn{position: absolute;top: 50%;background-color: #b4bcc5;width: 3px;height: 15px;cursor: pointer;transition: all 0.2s;transform: translate(1px, -50%);}
#containerWrap #sideLnb .sizeTool:hover .sizeToolBtn{background-color: #333948;}
#containerWrap #sideLnb .sizeTool .sizeToolBtn:hover{background-color: #333948;width: 5px;height: 50px;transform: translate(0px, -50%);}

#containerWrap #titleFnc{position: absolute;top: 0;left: 0;right: 0px;height: 50px;border-bottom: 1px solid #e5e5e5;box-sizing: border-box;}
#containerWrap #titleFnc .content-title{position: absolute;top: 0;left: 0;width: 50%;height: 50px;padding: 0 0 0 50px;box-sizing: border-box;}
#containerWrap #titleFnc .content-title .title-fnc{position: absolute;top: 16px;left: 19px;width: 18px;height: 18px;background: url('../imgs/ico/ic_smallthumb_m_normal.png') no-repeat center;cursor: pointer;}
#containerWrap #titleFnc .content-title .title-text{display: block;font-size: 18px;line-height: 2;letter-spacing: -0.5px;padding: 7px 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}

#containerWrap #titleFnc .content-title-fnc{position: absolute;top: 0;right: 0;width: 50%;height: 50px;overflow: hidden;}
#containerWrap #titleFnc .content-title-fnc:after {content:"";display:block;clear:both;height:0;visibility:hidden;}
#containerWrap #titleFnc .content-title-fnc ul{float: right;margin: 13px 15px 0 0;}
#containerWrap #titleFnc .content-title-fnc ul:after {content:"";display:block;clear:both;height:0;visibility:hidden;}
#containerWrap #titleFnc .content-title-fnc ul li{float: right;margin: 0 0 0 5px;}
#containerWrap #titleFnc .content-title-fnc ul li span{display:block;width: 24px;height: 24px;cursor: pointer;}
#containerWrap #titleFnc .content-title-fnc ul li .favorites{background: url('../imgs/ico/ic_favorite_off.png') no-repeat center;}
#containerWrap #titleFnc .content-title-fnc ul li .favorites.on{background: url('../imgs/ico/ic_favorite_on.png') no-repeat center;}

#containerWrap #contents{position: absolute;top: 120px;left: 260px;right: 0;bottom: 0;}
//#containerWrap #contents > div{min-height:536px;}
#contents {min-width:1032px;}

.tableRow:hover {background: #f7f7f7;cursor: pointer;}


